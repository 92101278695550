<template>
  <div class="couponList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>优惠券列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>分类名称:</span>
      <el-input
        v-model.trim="list.cateName"
        placeholder="请输入优惠券分类名称"
      ></el-input>

        <el-button type="primary" @click="getCouponList()">查找</el-button>
       <el-button type="primary" @click="$router.push(`/addCouponCate`)"
        >添加</el-button
      >
    </div>
    <el-table
      @selection-change="onSelectChange"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column
        align="center"
        type="selection"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cateId"
        label="优惠券ID"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cateName"
        label="优惠券分类名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="	添加时间"
        width="200"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            v-if="row.status === 2 && $store.state.powerList.indexOf('coupon:info:list:update') !== -1"
            @click="onChangeStatus(row.couponId, 1)"
            >上架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-if="row.status === 1 && $store.state.powerList.indexOf('coupon:info:list:update') !== -1 "
            @click="onChangeStatus(row.couponId, 2)"
            >下架</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="$router.push(`/addCouponCate?id=${row.cateId}`)"
            >编辑</el-button
          >
          <el-button type="primary" size="mini" @click="onDelRow(row.cateId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { couponCateListAPI,couponDelAPI,couponAllCateAPI } from "../../../api/coupon";
export default {
  name: "couponList",
  data() {
    return {
       fit:'fill',
      tableData: [],
      cate:null,
      pagination: {},
      ids: "",
      options: [
        {
          value: 1,
          label: "手动领取",
        },
        {
          value: 2,
          label: "注册领取",
        },
      ],
      options1: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
      ],
      list: {
        currentPage: 1,
        pageSize: 5,
        cateName: "",
        receiveWay: null,
        status: null,
      },
       dialogVisible: false,
      QRCode:'',
    };
  },
  created() {
    this.getCouponList();
  },
  methods: {
    async getCouponList() {
      const { data } = await couponCateListAPI(this.list);
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onDelRow(id) {
      this.$confirm("是否要设置这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await couponDelAPI({cateId:id});
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getCouponList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.couponId);
      });
      this.ids = arr.join(",");
    },
    async onChangeAll(status) {
      this.$confirm("是否要批量设置这些数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids == "") {
            return this.$message({
              type: "error",
              message: "请选择要设置的数据",
            });
          }

          const { data } = await changeStatus({
            ids: this.ids,
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getCouponList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onClear(v) {
      if (v == 1) {
        this.list.receiveWay = null;
      } else if (v == 2) {
        this.list.status = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getCouponList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getCouponList();
    },
  },
};
</script>
<style lang="less" scoped>
.couponList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 300px;
    }
    span {
      margin: 0 20px 0 40px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .btn {
    margin-top: 50px;
    float: left;
    
  }
}
</style>